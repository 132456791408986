<template>
  <div id="event-list-page">
    <div
      class="banner cvr-bg"
      style="background-image: url('/img/dummy/event/1.jpg');"
    >
      <div class="wrapper">
        <h1>Events</h1>
        <div class="txt">
          <p>Find any information about open house, seminar, and exhibition</p>
        </div>
        <!-- end of txt -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of banner -->

    <div class="inner-event">
      <div class="filter-trigger"><a href="#" class="cvr-bg-bf">Filter</a></div>
      <div class="filter">
        <div class="headback light">
          <div class="wrapper">
            <a href="#" class="cvr-bg-bf close-popup" ref="close">Filter</a>
          </div>
          <!-- end of wrapper -->
        </div>
        <!-- end of headback -->
        <div class="wrapper">
          <form>
            <div class="holder">
              <fieldset class="cvr-bg-af select">
                <select v-model="type" @change="getEvents">
                  <option :value="{}">{{ $t("event.AllType") }}</option>
                  <option
                    v-for="(type, index) in types"
                    :key="index"
                    :value="type"
                  >
                    {{ type.name }}
                  </option>
                </select>
              </fieldset>
              <fieldset class="cvr-bg-af select">
                <select v-model="location" @change="getEvents">
                  <option :value="{}">{{ $t("event.AllLocation") }}</option>
                  <option
                    v-for="(location, index) in locations"
                    :key="index"
                    :value="location"
                  >
                    {{ location.name }}
                  </option>
                </select>
              </fieldset>
              <fieldset class="cvr-bg-af">
                <date-picker
                  v-model="date"
                  format="DD MMMM YYYY"
                  name="date"
                  :first-day-of-week="1"
                  lang="en"
                  :placeholder="$t('event.Date')"
                  class="datepicker-here"
                  :input-class="'date'"
                  @pick="getEvents"
                ></date-picker>
              </fieldset>
            </div>
            <!-- end of holder -->
            <button type="button" @click="$refs.close.click()" class="button">
              Apply
            </button>
          </form>
        </div>
        <!-- end of wrapper -->
      </div>
      <!-- end of filter -->
      <div class="main">
        <div class="wrapper">
          <div class="rowflex">
            <template v-for="(event, index) in events">
              <div class="item" :key="index">
                <a
                  href="javascript:"
                  :data-src="`#popup-${index}`"
                  data-fancybox
                >
                  <figure>
                    <img :src="event.banner" @click="showModal = true" />
                    <figcaption :style="{ background: colors[event.type] }">
                      {{ event.type }}
                    </figcaption>
                  </figure>
                  <div class="caption">
                    <h2>
                      {{ event.title }}
                    </h2>
                    <div class="meta" style="display: inline-block">
                      <span
                        class="ic-loc cvr-bg-bf"
                        style="display: inline-block"
                        >{{ event.location }}</span
                      >
                      <br /><br />
                      <span class="ic-date cvr-bg-bf" style="display: inline-block">
                        {{ event.startDate | date_only }}
                        <template
                          v-if="
                            event.startTime !== null &&
                              event.startTime !== 'null'
                          "
                        >
                          {{ event.startTime }}
                        </template>
                        -
                        {{ event.endDate | date_only }}
                        <template
                          v-if="
                            event.endTime !== null && event.endTime !== 'null'
                          "
                        >
                          {{ event.endTime }}
                        </template>
                      </span>
                    </div>
                    <!-- end of meta -->
                  </div>
                  <!-- end of caption -->
                </a>
                <div
                  :id="`popup-${index}`"
                  class="popup-event"
                  style="display: none;"
                >
                  <div class="headback light">
                    <div class="wrapper">
                      <a
                        href="#"
                        class="cvr-bg-bf close-popup"
                        @click="closePopup"
                        >Detail Event</a
                      >
                    </div>
                    <!-- end of wrapper -->
                  </div>
                  <!-- end of headback -->
                  <div class="holder">
                    <h2>{{ event.title }}</h2>
                    <div class="content">
                      <figure>
                        <img :src="event.banner" />
                      </figure>
                      <div class="copy">
                        <div class="txt" v-html="event.description"></div>
                        <!-- end of txt -->
                        <!-- <div class="centered">
                          <template v-if="$store.state.userdata">
                            <a
                              href="#"
                              class="button medwide"
                              data-src="#popup-logout"
                              data-fancybox
                              @click="selectedEvent = event"
                            >
                              {{ $t("event.Book") }}
                            </a>
                          </template>
                          <template v-else>
                            <a
                              href="#"
                              class="button medwide"
                              data-src="#login-register"
                              data-fancybox
                            >
                              {{ $t("event.Book") }}
                            </a>
                          </template>
                        </div> -->
                        <!-- en dof centered -->
                      </div>
                      <!-- end of copy -->
                    </div>
                    <!-- end  of content -->
                  </div>
                  <!-- end of holder -->
                </div>
                <!-- end of popup -->
              </div>
              <!-- end of item -->
            </template>
            <!-- end of popup -->
          </div>
          <!-- end of rowflex -->
          <pagination
            :total-pages="totalPages"
            :total="totalPages"
            :per-page="perPage"
            :current-page="currentPage"
            @pagechanged="onPageChange"
          ></pagination>
          <!-- end of pagination -->
        </div>
        <div
          id="popup-logout"
          class="popup-logout form-basic"
          style="display: none;"
        >
          <h2>Choose Date</h2>
          <form>
            <div class="gap" style="margin-bottom: 20px">
              <fieldset>
                <input
                  v-model="bookingDate"
                  type="date"
                  id="bookingDate"
                  name="bookingDate"
                />
              </fieldset>
            </div>
          </form>
          <a href="#" ref="close" class="button btn-cancel">Cancel</a>
          <a href="#" class="button btn-confirm" @click="bookEvent">Submit</a>
        </div>
      </div>
    </div>
    <!-- end of inner event -->
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import $ from "jquery";

export default {
  name: "Event",
  metaInfo: {
    title: "Event"
  },
  components: {
    DatePicker,
    Pagination
  },
  data() {
    return {
      types: [],
      locations: [],
      type: {},
      location: {},
      date: null,
      events: [],
      currentPage: 1,
      perPage: 9,
      totalData: 1,
      totalPages: 1,
      colors: {
        "Open House": "#219653",
        Seminar: "#9B51E0",
        Exhibition: "#EB5757"
      },
      selectedEvent: null,
      bookingDate: null
    };
  },
  watch: {
    currentPage() {
      this.getEvents();
    }
  },
  mounted() {
    this.getTypes();
    this.getLocations();
    this.getEvents();

    $(".inner-event .filter-trigger a").on("click", function(e) {
      e.preventDefault();
      $(".inner-event .filter").fadeIn("fast");
    });
    $(".inner-event .filter .headback a").on("click", function(e) {
      e.preventDefault();
      $(".inner-event .filter").fadeOut("fast");
    });
    $(".popup-logout .button.btn-cancel").on("click", function(e) {
      e.preventDefault();
      $(this)
        .closest(".popup-logout")
        .find(".fancybox-close-small")
        .trigger("click");
    });
  },
  methods: {
    getTypes() {
      this.$axios.get(`/misc/types`).then(respponse => {
        this.types = respponse.data.data;
      });
    },
    getLocations() {
      this.$axios.get(`/misc/locations`).then(respponse => {
        this.locations = respponse.data.data;
      });
    },
    async getEvents() {
      await this.$axios
        .get(`/events`, {
          params: {
            page: this.currentPage,
            limit: this.perPage,
            sortBy: "id.asc",
            location: this.location.name,
            type: this.type.id,
            date:
              this.date !== null ? moment(this.date).format("YYYY-MM-DD") : null
          }
        })
        .then(response => {
          this.events = response.data.data.rows;
          this.totalData = response.data.data.count;
          this.totalPages = response.data.data.totalPages;
        });
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    bookEvent() {
      this.$axios
        .post(`/events/${this.selectedEvent.id}/booking`, {
          date: this.bookingDate
        })
        .then(response => {
          this.$swal({
            icon: "success",
            text: response.data.message
          });
          this.$refs.close.click();
        })
        .catch(error => {
          this.$swal({
            icon: "error",
            text: error.response.data.message
          });
        });
    },
    closePopup() {
      window.location.reload();
    }
  }
};
</script>

<style scoped>
.mx-datepicker {
  width: 100% !important;
}
.inner-event .main .item .meta span {
  flex: auto !important;
}
</style>
